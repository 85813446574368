<template>
	<div id="reigster" class="login-wrap" :style="loginbg">
		<div class="login-con">
            <img v-if="config && config.login_front_back_url" class="login-con-left" :src="config.login_front_back_url" alt="">
			<img v-else class="login-con-left" src="@/assets/img/login-left.png" alt="">
			<div class="login-con-form">
				<div class="title">注册智慧农场管理账号</div>
				<div class="title-eng">Sign up for smart farm management account</div>
				<div class="form">
					<form @submit.prevent="register($event)">
						<div class="form-input" :class="focus == 1 ?'form-input-active':''">
							<i class="ri-user-2-line ri-lg ri-lg"></i>
							<p class="form-holder" :class="(focus == 1 || mobile !='' ) ?'active':''">请输入手机号</p>
							<input type="text" v-model="mobile" @blur="onBlur" @focus="getFocus(1)">
						</div>
						<div class="form-input" :class="focus == 2 ?'form-input-active':''">
							<i class="ri-shield-keyhole-line ri-lg ri-lg"></i>
							<p class="form-holder" :class="(focus == 2 || code !='' ) ?'active':''">验证码</p>
							<input type="text" v-model="code" @blur="onBlur" @focus="getFocus(2)" style="width:150px">
							<a-button class="get-code-btn" v-if="send_status" disabled @click="getV2Code">{{time}}s后获取</a-button>
							<a-button class="get-code-btn" v-else type="primary" @click="getV2Code">获取验证码</a-button>
						</div>
						<div class="form-input" :class="focus == 3 ?'form-input-active':''">
							<i class="ri-key-line ri-lg ri-lg"></i>
							<p class="form-holder" :class="(focus == 3 || password !='' ) ?'active':''">设置密码</p>
							<input type="password" v-model="password" @blur="onBlur" @focus="getFocus(3)">
						</div>
						<div class="form-input" :class="focus == 4 ?'form-input-active':''">
							<i class="ri-key-line ri-lg ri-lg"></i>
							<p class="form-holder" :class="(focus == 4 || re_password !='' ) ?'active':''">再次输入密码</p>
							<input type="password" v-model="re_password" @blur="onBlur" @focus="getFocus(4)">
						</div>
							<button type="submit" class="form-btn">立 即 注 册</button>
						<div class="go-register">
							<router-link to="/login">
								<span @click="toRegister">已有账号?立即登录</span>
							</router-link>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="footer-version" v-if="config"><span v-html="config.footer"></span></div>
	</div>
</template>

<script>
import { reactive, toRefs,computed } from 'vue'
import loginModel from '@/api/login.js'
import tool from '@/util/tool.js'
export default{
	setup(){
		const _d = reactive({
			focus:"0",
			mobile:"",
			password:"",
			re_password:"",
			code:"",
			captchaObj:null,	//极验对象
			verify:"",			//发送的验证码信息
			time:0,				//倒计时
			timer:null,			//倒计时对象
			is_check:true,		//是否开启极验
			send_status:0,		//发送验证码状态
			config:null,
		})
		initData();
		//初始化获取配置信息
		function initData(){
			loginModel.getLoginSet().then(res=>{
				_d.config = res

				loginModel.getGeeTestVerify().then(result=>{
					if( result.is_check ){
						
						let data = result.data.geetest_response
						//请检测data的数据结构， 保证data.gt, data.challenge, data.success有值
						initGeetest({
							// 以下配置参数来自服务端 SDK
							gt: data.gt,
							challenge: data.challenge,
							offline: !data.success,
							new_captcha: true,
							product: 'bind',
						}, function (captchaObj) {
							_d.captchaObj = captchaObj
							captchaObj.onReady(function(){
								//验证码ready之后才能调用verify方法显示验证码
							}).onSuccess(function(){
								var obj = captchaObj.getValidate();
								sendMobileCode( { geetest_userid:result.data.geetest_userid,...obj } )
							}).onError(function(err){
								console.log('err',err);
							})
						})
					}else{
						_d.is_check = false
					}
				})
			})
		}

		const loginbg = computed(()=>{
			if( _d.config ) return `background-image: url(${_d.config.login_back_url});`;
			return ""
		})

		//input失去焦点
		function onBlur(){
			_d.focus = 0
		}
		//input获得焦点
		function getFocus(e){
			_d.focus = e
		}

		function getV2Code(){
			if( !_d.mobile ){
				tool.message("请先输入手机号");
				return
			}
			if( _d.is_check ){
				_d.captchaObj.verify()
			}else{
				sendMobileCode( null )
			}
		}

		//发送短信验证码
		function sendMobileCode(verify){
			loginModel.sendShortMsgCode(_d.mobile,1,verify).then(res=>{
				_d.verify = res
				_d.send_status = 1		//已发送状态
				const TIME_COUNT = 60
				if( !_d.timer ){
					_d.time = TIME_COUNT
					_d.timer = setInterval(()=>{
						if( _d.time >0 && _d.time <=TIME_COUNT){
							_d.time--;
						}else{
							clearInterval(_d.timer)
							_d.timer = null
							_d.send_status = 0	
						}
					},1000)
					return
				}
			})
		}

		//立即注册
		function register(){
			let data = { 
				mobile:_d.mobile,
				password:_d.password,
				code:_d.code,
				re_password:_d.re_password,
				verify:_d.verify
			}
			if( !data.mobile ){
				tool.message("请输入手机号","warning");
				return;
			}
			if( !data.verify ){
				tool.message("请先获取验证码","warning")
				return
			}
			if( !data.code ){
				tool.message("请输入验证码","warning");
				return
			}
			if( !data.password ){
				tool.message("请设置密码","warning");
				return
			}

			if(data.password !=data.re_password){
				tool.message("两次输入密码不一致","warning");
				return
			}
			loginModel.accountRegister(data);
		}

		return{
			...toRefs(_d),
			loginbg,
			onBlur,
			getFocus,
			getV2Code,
			register
		}
	}
}
</script>

<style lang="scss" scoped>
	html,body{
	    height: 100%;
	}
	body{
	    background-color: #f0f2f5;
		padding: 0;
		margin: 0;
	}
	.login-wrap{
	    display: table;
		width:100%;
		border: 1px solid green;
		position:fixed;
		height: 100%;
		text-align: center;
		background-image: url(../../assets/img/login-bg.png);
	}
	.login-con{
		width: 1000px;
		height: 500px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: flex;
		border-radius: 12px;

		&-left{
			width: 500px;
			height: 500px;
		}

		&-form{
			width: 500px;
			height: 500px;
			background: #fff;
			border-radius: 0 12px 12px 0;
			text-align: center;
			padding-top: 32px;

			.title{
				width: 100%;
				text-align: center;
				font-size: 24px;
				color: #1890ff;
				font-weight: bold;
			}
			.title-eng{
				color: #1890ff;
				font-size: 12px;
			}

			.form{
				width: 300px;
				margin-left: 100px;
				margin-top: 20px;

				.form-input{
					display: flex;
					align-items: center;
					height: 48px;
					border-bottom: 2px solid;
					padding: 0 12px;
					margin-bottom: 20px;
					border-bottom-color: rgba($color: #4a9ef4, $alpha: .6);

					>i{
						color: #4a9ef4;
					}
					
					input{
						width: 250px;
						height: 48px;
						margin-left: 12px;
						border: none;
						outline: none;
						font-size: 18px;
						background: none;
						z-index: 9;
					
						position: absolute;
						margin-left: 32px;
					}
					.form-holder{
						margin-left: 12px;
						font-size: 18px;
						color: #999;
						margin-top: 14px;
						transition: linear .2s;
					}

					.active{
						font-size: 12px;
						margin-top: -36px;
					}
				}
				.form-input-active{
					border-bottom-color: #4a9ef4;
				}
				.form-btn{
					width: 100%;
					height: 48px;
					border-radius: 6px;
					margin-top: 24px;
					border: none;
					background: #1890ff;
					color: #fff;
					font-size: 18px;
					cursor: pointer;
					transition: linear .2s;
					outline: none;
				}
				.form-btn:hover{
					background: rgba($color: #1890ff, $alpha: .8);
				}

				.get-code-btn{
					position: absolute;
					margin-left: 186px;
				}
			}
		}

		.lc-tips {
			font-size: 12px;
			color: #999;
			margin-top: 40px;

			a {
				text-decoration: none;
				color: #29adeb;
			}
		}
	}
	.footer-version {
		width: 100%;
		height: 30px;
		line-height: 30px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		bottom: 0;
		text-align: center;
		color: #ffffff;
		font-size: 12px;

		p {
			line-height: 30px;
			margin: 0;
			padding: 0;
		}
	}
	.go-register {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 12px;
		color: #4a4a4a;
		cursor: pointer;
		text-align: right;
		span:hover {
			color: #007aff;
		}
	}
	
</style>
